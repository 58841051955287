import React,{useState,useEffect} from 'react'
const { WebLink } = require('../../../Export/ExportLink');
const axios = require('axios');


// require('dotenv').config()


const validation =(inputs)=>{
 
  const error = {};
  if (!inputs.DoctorName) {
      error.DoctorName = 'Blank Input Not Accepted';
  } 
  if (!inputs.Designation) {
      error.Designation = 'Blank Input Not Accepted';
  } 
  if (!inputs.Qualification) {
    error.Qualification = 'Blank Input Not Accepted';
} 

  if (!inputs.ParaOne) {
    error.ParaOne = 'Blank Field Not Accepted';
  } 

  if (!inputs.ParaTwo) {
    error.ParaTwo = 'Blank Field Not Accepted';
  } 

  if (!inputs.Image) {
    error.Image = 'Blank Field Not Accepted';
  } 

  return error
}


const AddTeams = () => {

  const [inputs,setInputs] = useState("");
  const [errors,setErrors] = useState("");

  const handleSubmit = (event) => {
    if(event){
      event.preventDefault();
    }
    const err = validation(inputs);
    
    setErrors(err);
    
    if(Object.keys(err).length === 0){
        console.log(inputs);

      const data = new FormData();
      data.append("Designation",inputs["Designation"]);
      data.append("DoctorName",inputs["DoctorName"]);
      data.append("Qualification",inputs["Qualification"]);
      data.append("ParaOne",inputs["ParaOne"]);
      data.append("ParaTwo",inputs["ParaTwo"]);
      data.append("ParaThree",inputs["ParaThree"]);
      data.append("ParaFour",inputs["ParaFour"]);
      data.append("Image",inputs["Image"]);


      //axios call start
      let ID = (window.location.pathname).split("/").pop();
      axios.put(WebLink+"/Doctor/"+ID,data,{headers:{token:sessionStorage.getItem("token")}})
      .then(function (response) {
        
        if(response.data.status == 403) return window.location = "/";
        alert(response.data.text);
        window.location = "/teamMembers"
      })
      .catch(function (error) {
        console.log(error);
      });
      //axios call end
      //console.log(inputs);
    }
    else
      console.log(errors);
  }

  const handleInputChange = (event) => {
    event.persist();
    
    if (event.target.name == "Image") setInputs(inputs => ({...inputs, [event.target.name]: event.target.files[0]}));
    else setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  useEffect( ()=> {
    let ID = (window.location.pathname).split("/").pop();
   
    axios.get(WebLink+"/Doctor/"+ID,{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
        if(response.data.status == 403) window.location = "/";
        setInputs(response.data)
        // console.log(response);
        return response.data
      })
      .catch(function (error) { 
        console.log(error)
      })
    },[]);


  return (
    <div className="home">

       <div className="container">
        <h2 className="heading">Add New Team Member </h2>
        <hr/>
        <form  className="form" onSubmit={handleSubmit} encType="multipart/form-data"  autoComplete="off" >
          <div>
            <label > Name </label>
            <input type="text" name="DoctorName"  onChange={handleInputChange} type="text" value={inputs.DoctorName} />
            {errors.DoctorName && <p className="errors">{errors.DoctorName}</p>}
          </div>
          <div>
            <label > Designation</label>
            <input type="text" name="Designation"  onChange={handleInputChange} type="text" value={inputs.Designation} />
            {errors.Designation && <p className="errors">{errors.Designation}</p>}
          </div>
          <div>
            <label > Qualification</label>
            <input type="text" name="Qualification"  onChange={handleInputChange} type="text" value={inputs.Qualification} />
            {errors.Qualification && <p className="errors">{errors.Qualification}</p>}
          </div>

          <div className="containerTextarea">
            <div className="col-25">
              <label className="" htmlFor="textarea"> Para One</label>
            </div>
             <div className="col-75">
              <textarea className="textarea"  id="textarea" type="text" name="ParaOne"  onChange={handleInputChange} type="text" value={inputs.ParaOne} className="textarea" ></textarea>
            </div>
            
            {errors.ParaOne && <p className="errors">{errors.ParaOne}</p>}
          </div>

          <div className="containerTextarea">
          <div className="col-25">
              <label  >Para Two</label>
            </div>
             <div className="col-75">
              <textarea className="textarea"   type="text" name="ParaTwo"  onChange={handleInputChange} type="text" value={inputs.ParaTwo} className="textarea" ></textarea>
            </div>
            
            {errors.ParaTwo && <p className="errors">{errors.ParaTwo}</p>}
          </div>

          <div className="containerTextarea">
          <div className="col-25">
              <label  >Para Three</label>
            </div>
             <div className="col-75">
              <textarea className="textarea"   type="text" name="ParaThree"  onChange={handleInputChange} type="text" value={inputs.ParaThree} className="textarea" ></textarea>
            </div>
            

          </div>
          <div className="containerTextarea">
          <div className="col-25">
              <label  >Para Four</label>
            </div>
             <div className="col-75">
              <textarea className="textarea"   type="text" name="ParaFour"  onChange={handleInputChange} type="text" value={inputs.ParaFour} className="textarea" ></textarea>
            </div>

          </div>


          <div>
            <label className="imagetag"> Image</label>
            <input type="file" name="Image" onChange={handleInputChange}  />
            {errors.Image && <p className="errors">{errors.Image}</p>}
          </div>
          <div className="submitDiv" >
              <input type="submit" value="Submit" className="submitbtn" />
          </div>
          
        </form>
      </div>

         
      

    </div>
  )
}

export default AddTeams
