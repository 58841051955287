import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';


import "antd/dist/antd.css";
import { Menu } from "antd";



const { SubMenu } = Menu;

// submenu keys of first level
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const logout = () =>{
    sessionStorage.clear();
    window.location = "/";
  }


  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className="MidDivSection"> <h5 >Entira Admin Panel </h5 > </div>
        <div className='navbar'>
          <Link to='#' className='navIcon menu-bars '>
          {/* {checkLogin() ? (<FaIcons.FaBars onClick={showSidebar} />) : null} */}
          {window.location.pathname == "/" ? null : <FaIcons.FaBars onClick={showSidebar} /> }
            
          </Link>
          <div className='navbarText'><h5 >Entira Admin Panel </h5 ></div>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' >
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>

                {console.log(window.location.pathname == "/")}
                {window.location.pathname == "/" ? null : <AiIcons.AiOutlineClose  onClick={showSidebar}/> }
                {/* <AiIcons.AiOutlineClose  onClick={showSidebar}/> */}
                {/* <AiIcons.AiOutlineClose  onClick={showSidebar}/> */}
              </Link>
            </li>
              <Menu
              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              style={{ width: "256px",height:"100vh",zIndex:"-1",backgroundColor:"#fff" }}
            >
              <div className="gapPadding" ></div>
              {
                SidebarData.map((item,index)=>{
                  if(item.subMenu){
                    return(
                      <SubMenu key={index}  title={item.title}>
                        {item.subMenu.map((menu,count)=>{
                         return ( 
                            <Menu.Item key={count} ><Link to={menu.path} >{menu.title}</Link></Menu.Item>
                         )
                        })}
                        
                      </SubMenu>
                    )
                  }else {
                    return (<Menu.Item  key={index} > <Link to={item.path} >{item.title}</Link> </Menu.Item>)
                  }
                  
                })
              }
              <Menu.Item  onClick={()=>logout()}><span  style={{color:"red"}} >Logout</span></Menu.Item>
              {/* <Menu.Item  ><span  style={{color:"red"}} >Logout</span></Menu.Item> */}
              </Menu>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
