


import React,{useEffect,useState} from 'react';
import './Login.css';
const axios = require('axios');

const { WebLink } = require('../../Export/ExportLink');

const validation =(inputs)=>{
 
    const error = {};
    if (!inputs.userName) {
        error.HeadingOne = 'Blank User Name Not Accepted';
    } 
    if (!inputs.password) {
        error.HeadingOne = 'Blank password Not Accepted';
    }
    
  
    return error
  }


const Login = () => {

    const [inputs,setInputs] = useState("");
    const [errors,setErrors] = useState("");

    useEffect(()=>{
        axios.get(WebLink+"/News",{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
            if(response.data.status != 403) return window.location = "/teamMembers";
              return response
            })
            .catch(function (error) {
              console.log(error)
            })
        // window.location = "/teamMembers";
        
    })

    const handleSubmit = (event) => {
        if(event){
        event.preventDefault();
        }
        const err = validation(inputs);
        
        setErrors(err);
        
        if(Object.keys(err).length === 0){
           console.log(inputs);
           axios.post(WebLink+"/Login",inputs)
           .then(function (response) {
               console.log(response);
             if (response.data.status == 200) {sessionStorage.setItem("token",response.data.token) ;return window.location = "/appointments";}
             else alert("Wrong Credentials")
           
           })
           .catch(function (error) {
             console.log(error);
           });
        }
        else
        console.log(errors);
    }

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
      }
    return (
        <div className="home">
            <div className="container123">
                
                    <form className="form123" onSubmit={handleSubmit} autoComplete="off" >
                    <div className="container">
                        <label htmlFor="uname"><b>Username</b></label>
                        <input type="text" className="username" placeholder="Enter Username" name="userName" onChange={handleInputChange} required/>

                        <label htmlFor="psw"><b>Password</b></label>
                        <input type="password" className="password" placeholder="Enter Password" onChange={handleInputChange} name="password" autoComplete="off" required/>
                            
                        <button type="submit" style={{color:"#f1f1f1"}}>Login</button>
                        
                    </div>

                    <div className="container" style={{backgroundColor:"#f1f1f1"}}>
                        
                        {/* <span className="psw">Forgot <a href="#">password?</a></span> */}
                    </div>
                    </form>
            </div>
            
        </div>
    )
}

export default Login
