
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from '../Login/Login';
import AddNews from '../Pages/News/AddNews';
import AllNews from '../Pages/News/AllNews';
import AddVideos from '../Pages/Videos/AddVideos';
import UpdateNews from '../Pages/News/UpdateNews';
import AddTeams from '../Pages/Teams/AddTeams';
import AllTeams from '../Pages/Teams/AllTeams';
import UpdateTeams from '../Pages/Teams/UpdateTeams';

import Navbar from './Navbar/Navbar';
import SearchAppt from '../Pages/Appointments/searchAppt';
import SearchApptTable from '../Pages/Appointments/SearchApptTable';
import AllAppt from '../Pages/Appointments/AllAppt';
import AllVideos from '../Pages/Videos/AllVideos';
import PageVisit from "../Pages/PageVisit/pageVisit"



const Menu = () => {
    return (
        <>
         <Router>
          <Navbar />
          <Switch>
          

            <Route path='/TeamMember/:id' exact component={UpdateTeams} />
            <Route path='/teamMembers'  component={AllTeams} />
            <Route path='/addTeamMember'  component={AddTeams} />
            


            <Route path='/News/:id' exact component={UpdateNews} />
            <Route path='/News' exact component={AllNews} />
            <Route path='/addNews' exact component={AddNews} />

            <Route path='/allVideos' exact component={AllVideos} />
            <Route path='/videos' exact component={AddVideos} />

            <Route path='/page-visit' exact component={PageVisit} />

            <Route path='/appointments' exact component={AllAppt} />
            <Route path='/searchAppointment' exact component={SearchAppt} />
            <Route path='/searchAppointmentTable' exact component={SearchApptTable} />

            
            
            
            <Route path='/' exact component={Login} />
            
            
            
          </Switch>
        </Router> 
      </>
    )
}

export default Menu;
