import React,{useState,useEffect} from 'react';
import 'antd/dist/antd.css';
import {Link} from 'react-router-dom'
import { Modal, Button } from 'antd';
import './table.css';
/* */
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
/* */
import { MdDeleteForever,MdUpdate,MdArrowForward,MdFastForward } from 'react-icons/md';
import { FcViewDetails } from 'react-icons/fc';

const axios = require('axios');
const { WebLink } = require('../../../Export/ExportLink');


// const useStyles = makeStyles(() => ({
//   table: {
//     minWidth: 650,
//   },
// }));



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <MdFastForward /> : <MdFastForward style={{transform: "rotate(180deg)"}} />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <MdArrowForward />
        ) : (
          <MdArrowForward style={{transform: "rotate(180deg)"}} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <MdArrowForward style={{transform: "rotate(180deg)"}} />
        ) : (
          <MdArrowForward />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <MdFastForward style={{transform: "rotate(180deg)"}} /> : <MdFastForward />}
      </IconButton>
    </Box>
  );
}

const AllVideos= ()=> {
  // const classes = useStyles();

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');

  const [TableInfo, setTableInfo] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TableInfo.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showModal = (body) => {
    setModalText(body);
    setVisible(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after one seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    //console.log('Clicked cancel button');
    setVisible(false);
  };

  const deleteItem = (_id) =>{
    console.log(_id)
    let c = window.confirm("Want to delete ? ")
    if (c){
      axios.delete(WebLink+'/Videos/delete/'+_id,{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
        alert(response.data.text);
        window.location.reload(false);
        return true
      })
      .catch(function (error) {
        console.log(error)
      })
    }
    
    return true
  }
  // get appts
  useEffect( ()=> {
    axios.get(WebLink+"/Videos/all",{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
      console.log(response)
      if(response.data.status == 403) return window.location = "/";
      setTableInfo(response.data)
      // setTableInfo(rows)

        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
    },[]);

    const DateParse=(date)=>{
      let d = new Date(date);
      return (d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()
    }
    const DateParseWithTime=(date)=>{
      let d = new Date(date);
    //   console.log(d.getHours(),d.getMinutes(),d.getSeconds())
      return (d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" - "+d.getHours()+" h:"+d.getMinutes()+" m:"+d.getSeconds()+" s"
    }
    
    const TimeParse=(date)=>{
        let d = new Date(date);
      //   console.log(d.getHours(),d.getMinutes(),d.getSeconds())
        return d.getHours()+" h:"+d.getMinutes()+" m:"+d.getSeconds()+" s"
      }

  return (
    <div className='home' style={{padding:"10px 40px"}}>
    
      <div >
        <div className="tableHeading">
          <h3>Videos</h3>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                
                <TableCell sx={{ minWidth: 300 }} align="right">Video Link</TableCell>
                
                <TableCell sx={{ minWidth: 100 }} align="right">Date&nbsp;</TableCell>
                <TableCell sx={{ minWidth: 200 }} align="right">Time&nbsp;</TableCell>
                
                
                <TableCell sx={{ minWidth: 100 }} align="center">Delete&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? TableInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : TableInfo
              ).map((row) => (
                <TableRow>
                  
                  <TableCell style={{ width: 300 }} align="right"><a href={row.VideoLink} target="_blank" rel="noopener noreferrer">{row.title}</a></TableCell>
                  
                  <TableCell style={{ width: 100 }} align="right">{DateParse(row.dateAdded)}</TableCell>
                  
                  
                  <TableCell style={{ width: 200 }} align="right">{TimeParse(row.dateAdded)}</TableCell>
                  <TableCell align="center"> <Button title="Delete" type="primary" onClick={()=>deleteItem(row._id)} className="buttonText"><MdDeleteForever style={{fontSize:"20px"}} /></Button></TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
           
          
              
            
          </Table>
          <TablePagination 
                   rowsPerPageOptions={[5, 10, 25]}
                   colSpan={3}
                   count={TableInfo.length}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   SelectProps={{
                     inputProps: {
                       "aria-label": "rows per page"
                     },
                     native: true
                   }}
                   onPageChange={handleChangePage}
                   onRowsPerPageChange={handleChangeRowsPerPage}
                   ActionsComponent={TablePaginationActions}
                     
                />
        </TableContainer>
      </div>
    </div>
  );
}

export default AllVideos;


// style={{height:"500px",display:"inline-block",overflow:"auto"}}

