import React,{useEffect,useState} from 'react';
import axios from 'axios';

import { WebLink } from '../../../Export/ExportLink'


const validation =(inputs)=>{
 
    const error = {};
    if (!inputs.Heading) {
        error.Heading = 'Blank Input Not Accepted';
    } 
    if (!inputs.Section) {
        error.Section = 'Blank Input Not Accepted';
    } 
    if (!inputs.Image) {
        error.Image = 'Blank Field Not Accepted';
      } 
  
    return error
}

const UpdateNews = () => {

    const [inputs,setInputs] = useState({});
    const [errors,setErrors] = useState("");
    const [pdfFile,setPdfFile] = useState({});
    const [image,setImage] = useState({});
    
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == "newsPdf") setPdfFile(event.target.files[0]);
    
      if (event.target.name == "Image") {
        //console.log(event.target.files[0], event.target.name)
        setImage(event.target.files[0])
      }
        else setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

      const handleSubmit = (event) => {
        if(event){
          event.preventDefault();
        }
        const err = validation(inputs);
        
        setErrors(err);
        
        if(Object.keys(err).length === 0){
            // console.log(inputs["Image"] );
    
            const data = new FormData();
          
         
            data.append("Heading",inputs["Heading"]);
            data.append("Section",inputs["Section"]);
            if ((image === undefined) || (image == {} )) data.append("Image","");
            else data.append("Image",image);

            if ((pdfFile === undefined) || (image == {} ) ) data.append("newsPdf","");
            else data.append("newsPdf",pdfFile);
         
    
          //axios call start
            let ID = (window.location.pathname).split("/").pop();
            axios.put(WebLink+"/News/"+ID,data,{headers:{token:sessionStorage.getItem("token"),'Content-Type': 'multipart/form-data'} } )
            .then(function (response) {
              if(response.data.status == 403) return window.location = "/";
              alert(response.data.text);
              window.location = "/News"
            })
            .catch(function (error) {
            console.log(error);
            });

          //axios call end
          //console.log(inputs);
        }
        else
          console.log(errors);
      }

    useEffect( ()=> {
        let ID = (window.location.pathname).split("/").pop();
       
        axios.get(WebLink+"/News/"+ID,{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
            if(response.data.status == 403) window.location = "/";
            setInputs(response.data)
            // console.log(response);
            return response.data
          })
          .catch(function (error) { 
            console.log(error)
          })
        },[]);

    return (
        <div className="home">
         
            <div className="container">
                <h2 className="heading">Update New News </h2>
                <hr/>
                <form  className="form" onSubmit={handleSubmit} encType="multipart/form-data"  autoComplete="off" >
                <div>
                    <label > Heading </label> 
                    <input  name="Heading"  onChange={handleInputChange} type="text" autoComplete="off" value={inputs.Heading} />
                    {errors.Heading && <p className="errors">{errors.Heading}</p>}
                </div>
                <div>
                    <label > Section </label>
                    <input  name="Section"  onChange={handleInputChange} autoComplete="off" type="text" value={inputs.Section} />
                    {errors.Section && <p className="errors">{errors.Section}</p>}
                </div>

                <div>
                    <label className="imagetag"> Image</label>
                    <input type="file" name="Image" onChange={handleInputChange}  />
                    {errors.Image && <p className="errors">{errors.Image}</p>}
                </div>
                <div>
                    <label className="imagetag"> PDF</label>
                    <input type="file" name="newsPdf" onChange={handleInputChange}  />
                    {errors.newsPdf && <p className="errors">{errors.newsPdf}</p>}
                </div>
                <div className="submitDiv" >
                    <input type="submit" value="Submit" className="submitbtn" />
                </div>            
                </form>
            </div>
        </div>
    )
}

export default UpdateNews
