
import './App.css';
import Menu from "./components/Menu/Menu"
function App() {
  return (
    <div >
      <Menu />

    </div>
  );
}

export default App;
