import React,{useState, useEffect } from 'react'
const { WebLink } = require('../../../Export/ExportLink');
const axios = require('axios');


// require('dotenv').config()


const validation =(inputs)=>{
 
  const error = {};
  if (!inputs.VideoLink) {
      error.VideoLink = 'Blank Input Not Accepted';
  } 

  if (!inputs.title) {
    error.title = 'Blank Input Not Accepted';
  } 

  return error
}


const AddVideos = () => {

  const [inputs,setInputs] = useState("");
  const [errors,setErrors] = useState("");
  const [video,setVideo] = useState('');

  const handleSubmit = (event) => {
    if(event){
      event.preventDefault();
    }
    const err = validation(inputs);
    
    setErrors(err);
    
    if(Object.keys(err).length === 0){
        console.log(inputs);

      let data = inputs

      //axios call start
      axios.post(WebLink+"/Videos",data,{headers:{token:sessionStorage.getItem("token")}})
      .then(function (response) {
        if(response.data.status == 403) return window.location = "/";
        if(response.status == 200){
          alert(response.data.text);
          window.location.reload();
        }
        
      })
      .catch(function (error) {
        console.log(error);
      });
      //axios call end
      //console.log(inputs);
    }
    else
      console.log(errors);
  }

  const handleInputChange = (event) => {
    event.persist();
    
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  
  useEffect( ()=> {
    axios.get(WebLink+"/Videos",{headers:{token:sessionStorage.getItem("token")}} ).then(function (response) {
      //  if(response.data.status == 403) window.location = "/";
      console.log(response);
      let link =  response.data[0].VideoLink
      setVideo(link)
      
      return response.data
    })
    .catch(function (error) {
      
      console.log(error)
    })
    
  },[]);

  return (
    <>
    <div className="home">

       <div className="container">
        <h2 className="heading">Add New Video </h2>
        <hr/>
        <form  className="form" onSubmit={handleSubmit} encType="multipart/form-data"  autoComplete="off" >
        <div>
            <label > Video Title </label>
            <input type="text" name="title"  onChange={handleInputChange}  value={inputs.title} />
            {errors.title && <p className="errors">{errors.title}</p>}
          </div>

          <div>
            <label > Video Link </label>
            <input type="text" name="VideoLink"  onChange={handleInputChange}  value={inputs.VideoLink} />
            {errors.VideoLink && <p className="errors">{errors.VideoLink}</p>}
          </div>

         
          

         
         
          <div className="submitDiv" >
              <input type="submit" value="Submit" className="submitbtn" />
          </div>
          
        </form>
        <hr />
        <h2 className="heading" style={{"textAlign":"left"}}>Latest video </h2>
        <div>
        <iframe width="100%" height="500"  src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      
      

         
      

    </div>
    
    </>
  )
}

export default AddVideos
