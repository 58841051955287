import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';


export const SidebarData = [
  {
    title: 'Teams',
    path: '',
    icon:"",
    cName: 'nav-text',
    subMenu:[
        {title:"Team Members",path:"/teamMembers",icon:"",cName:"nav-text"},
        {title:"Add Team Member",path:"/addTeamMember",icon:"",cName:"nav-text"}
    ]
  },
  {
    title: 'News',
    path: '',
    icon:"",
    cName: 'nav-text',
    subMenu:[
        {title:"All News",path:"/News",icon:"",cName:"nav-text"},
        {title:"Add News",path:"/addNews",icon:"",cName:"nav-text"}
    ]
  },{
    title: 'Videos',
    path: '',
    icon:"",
    cName: 'nav-text',
    subMenu:[
        {title:"All Videos",path:"/allVideos",icon:"",cName:"nav-text"},
        {title:"Add Videos",path:"/videos",icon:"",cName:"nav-text"}
    ]
  },
  {
    title: 'Appointments',
    path: '',
    icon:"",
    cName: 'nav-text',
    subMenu:[
        {title:"All Appointments",path:"/appointments",icon:"",cName:"nav-text"},
        {title:"Search Appointments",path:"/searchAppointment",icon:"",cName:"nav-text"}
    ]
  },
  {
    title: 'Page analysis',
    path: '',
    icon:"",
    cName: 'nav-text',
    subMenu:[
        {title:"Page Visit",path:"/page-visit",icon:"",cName:"nav-text"},
        // {title:"Search Appointments",path:"/searchAppointment",icon:"",cName:"nav-text"}
    ]
  }
  

  // {
  //   title: 'Single_menu',
  //   path: '/link',
  //   icon:"",
  //   cName: 'nav-text',
    
  // }
  
];