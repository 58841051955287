import React, { useEffect, useRef,useState } from 'react';
import Chart from "react-apexcharts";
import * as d3 from 'd3';
import StackedBarChart from "../../stackBarChart/StackedBarChart";
import colors from "../../stackBarChart/colors";
import keys from "../../stackBarChart/keys";
import data from "../../stackBarChart/data";
import axios from 'axios';
import "./LineChart.css";
const { WebLink } = require('../../../Export/ExportLink');


const PageVisit = () => {
  const d3Chart = useRef();

  const [data,setData] = useState([]);
  const [graphData,setGraphData] = useState({});
  const [uniVisitors,setUniVisitors] = useState(0) ;
	
  const parseDate = d3.timeParse('%Y-%m-%d');
	//   useEffect(()=>{
	// 		fetch('https://data.cityofnewyork.us/resource/tg4x-b46p.json')
	// 			.then(response => response.json())
	// 			.then(data=>{
	//         console.log("page visit data",data)
	// 				// Transform data
	// 				const permits = data.filter(event => {
	// 					return event.eventtype === 'Shooting Permit'
	// 				}) 

	// 				// Get all the dates in an array
	// 				const dates = [...new Set(permits.map(each=>each.enteredon.slice(0,10)))]

	// 				let CountsByDate = []

	// 				// Get counts(number of times a permit entered) on each date
	// 				dates.map(time=>{
	// 					let date = time
	// 					let count = 0

	// 					permits.map(each=>{
	// 						let timestamp = each.enteredon.slice(0,10)
	// 						if(timestamp === date) {count+=1}
	// 					})

	// 					const counts = {date:parseDate(date), count:count}

	// 					CountsByDate.push(counts)
	// 				})

	// 				console.log(CountsByDate)

	// 				const margin = {top: 20, right: 30, bottom: 30, left: 30}
	// 				const width = parseInt(d3.select('#d3demo').style('width')) - margin.left - margin.right
	// 				const height = parseInt(d3.select('#d3demo').style('height')) - margin.top - margin.bottom

	// 				// Set up chart
	// 				const svg = d3.select(d3Chart.current)
	// 								.attr('width', width + margin.left + margin.right)
	// 								.attr('height', height + margin.top + margin.bottom)
	// 								.append('g')
	// 									.attr('transform', 'translate('+ margin.left + ',' + margin.top + ')');

	// 				// x axis scale 
	// 				const x = d3.scaleTime()
	// 							.domain(d3.extent(CountsByDate, function(d){return d.date}))
	// 							.range([0,width])

	// 				svg.append('g')
	// 					.attr('transform', 'translate(0,' + height + ')')
	// 					.call(d3.axisBottom(x))

	// 				// Get the max value of counts
	// 				const max = d3.max(CountsByDate, function(d){return d.count})

	// 				// y axis scale 
	// 				const y = d3.scaleLinear()
	// 							.domain([0, max])
	// 							.range([height,0])

	// 				svg.append('g')
	// 					.call(d3.axisLeft(y))


	// 				// Draw line
	// 				svg.append('path')
	// 					.datum(CountsByDate)
	// 					.attr('fill', 'none')
	// 					.attr('stroke','black')
	// 					.attr('stroke-width', 2)
	// 					.attr('d', d3.line()
	// 								.x(function(d){return x(d.date)})
	// 								.y(function(d){return y(d.count)})
	// 						)

	// 				// Add title 
	// 				svg.append('text')
	// 					.attr('x',(width/2))
	// 					.attr('y', (margin.top/5 - 10))
	// 					.attr('text-anchor', 'middle')
	// 					.attr('font-size', '16px')
	// 					.attr('fill','white')
	// 					.text('New York City Film Permits entered in 2020 - Shooting Permit')
	// 			})
	// 	},[])

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmZTJlMzhiNWVlZTMxNWU4Yzc3NmQ2MyIsImlhdCI6MTYwODc0NDUzMX0.T8Ck96I4qaFnwwwDm_OVJ6Vv2i29ozG5PkssfrWH1t0";
  useEffect( ()=> {
    axios.get(WebLink+"/page-visit",{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
         if(response.data.status == 403) window.location = "/";
        if(response.data.result){
			const result = response.data.result;
			console.log("page_visit_result",result)
			setData(result)

			if(result.length == 0) return alert("No page visit");

			const data = {};const categories = [] ; const uniVisits = []
			for (let i = 0; i < result.length; i++) {
				const element = result[i];
				if(data[element.date]){
					data[element.date] += 1;
				}else{
					data[element.date] = 1;
					categories.push(element.date)
				}
				if(uniVisits.indexOf(element.hashCode) == -1){
					uniVisits.push(element.hashCode)
				}
			}
			console.log("data",uniVisits.length)
			setUniVisitors(uniVisits.length)
			const graphObject = {
				options: {
					chart: {
						type: "area", 
						zoom: {
						  enabled: true,
						  type: "x",
						  resetIcon: {
							offsetX: -10,
							offsetY: 0,
							fillColor: "#fff",
							strokeColor: "#000",
						  },
						  selection: {
							background: "#90CAF9",
							border: "#0D47A1",
						  },
						},
					  },
					  stroke: {
						curve: 'straight'
					  },
					  title: {
						text: '#visitors By Date',
						align: 'center'
					  },
					  grid: {
						row: {
						  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						  opacity: 0.5
						},
					  },
					  xaxis: {
						categories: Object.keys(data)
					  },
					},
				series: [
				  {
					name: "Visitors",
					data: Object.values(data)
				  }
				]
			  }
			  setGraphData(graphObject)
			  
			// 
		} //
		console.log("setGraphData",graphData)
		console.log("setGraphData2222",uniVisitors)
        
        return response.data
      })
      .catch(function (error) {
        
        console.log(error)
      })
    },[]);


  return (
    <>
    <div className='home' style={{padding:"10px 40px"}}>

		<div>
			<div className="tableHeading">
				<h3>Total Page Visit </h3>
					<h3>{data.length}</h3>
			</div>
			<hr />
			<div>
				{
					graphData.options ? 
						<Chart
							options={graphData.options}
							series={graphData.series}
							type="line"
							width="800"
						/> 
					: null
				}
				
			</div>
			<hr />
			<div>
				<h4>Unique Visitors: {uniVisitors}</h4>
			</div>
		</div>

		

    </div>
   
  </>


  )
}

export default PageVisit;


{/* <div >
          {/* https://codesandbox.io/s/hnyre?file=/src/StackedBarChart.js 
      {/* <StackedBarChart colors={colors} data={data} keys={keys} /> 
    </div> */}