import React,{useState,useEffect} from 'react';
import 'antd/dist/antd.css';
import {Link} from 'react-router-dom'
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';
// import './table.css';
/* */
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
/* */
import { MdDeleteForever,MdUpdate,MdArrowForward,MdFastForward } from 'react-icons/md';
import { FcViewDetails } from 'react-icons/fc';

const axios = require('axios');
const { WebLink } = require('../../../Export/ExportLink');


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <MdFastForward /> : <MdFastForward style={{transform: "rotate(180deg)"}} />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <MdArrowForward />
        ) : (
          <MdArrowForward style={{transform: "rotate(180deg)"}} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <MdArrowForward style={{transform: "rotate(180deg)"}} />
        ) : (
          <MdArrowForward />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <MdFastForward style={{transform: "rotate(180deg)"}} /> : <MdFastForward />}
      </IconButton>
    </Box>
  );
}

const SearchApptTable= (props)=> {
  const inputs = (props.location && props.location.state) || {};
  // console.log(props)
  console.log(inputs)
  const [isEmpty, setIsEmpty] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [TableInfo, setTableInfo] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TableInfo.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showModal = (body) => {
    setModalText(body);
    setVisible(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after one seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    //console.log('Clicked cancel button');
    setVisible(false);
  };

  const deleteItem = (_id) =>{
    let c = window.confirm("Want to delete ? ")
    if (c){
      axios.delete(WebLink+'/appt/'+_id,{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
        alert(response.data.text);
        window.location.reload(false);
        return true
      })
      .catch(function (error) {
        console.log(error)
      })
    }
    
    return true
  }
  
  
  // get appts
  useEffect( ()=> {
    if(inputs){
        axios.post(WebLink+"/appt/search",inputs,{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
            console.log(response)
            if(response.data.status == 403) return window.location = "/";
            if(response.data.length == 0){
              setIsEmpty(true);
              return alert("No Data Found");
            }else if(response.data.length != 0){
              setTableInfo(response.data)
            }else{
              return alert("Something wrong!")
            }
                
                // setTableInfo(rows)
                //   return 0
                return response.data
            }).catch(function (error) {
                console.log(error)
            })
        }
    },[]);

    const DateParse=(date)=>{
      let d = new Date(date);
      return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()
    }
    const DateParseWithTime=(date)=>{
      let d = new Date(date);
      // console.log(d.getHours(),d.getMinutes(),d.getSeconds())
      return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()+" - "+d.getHours()+" h:"+d.getMinutes()+" m:"+d.getSeconds()+" s"
    }
    

  return (
    <div className='home'>
    
      <div >
        <div className="tableHeading">
          <h3>Search Results</h3>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="right">Date&nbsp;</TableCell>
                <TableCell align="right">Time&nbsp;</TableCell>
                <TableCell align="right">View&nbsp;</TableCell>
                <TableCell align="right">Delete&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? TableInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : TableInfo
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell style={{ width: 150 }} >{row.name}</TableCell>
                  <TableCell style={{ width: 100 }} align="right">{row.email}</TableCell>
                  <TableCell style={{ width: 100 }} align="right">{row.phoneNumber}</TableCell>
                  <TableCell style={{ width: 100 }} align="right">{row.scheduleDate}</TableCell>
                  <TableCell style={{ width: 140 }} align="right">{row.scheduleTime}</TableCell>
                  <TableCell style={{ width: 100 }} align="right">
                  <Button type="primary" title="Details" onClick={()=>showModal(row)} className="buttonText">< FcViewDetails style={{fontSize:"20px",color:"white"}} /></Button>
                    <Modal
                        title={ (<h2>{ modalText.name}</h2>)}
                        visible={visible}
                        confirmLoading={confirmLoading}
                        keyboard ={true}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleCancel}>Close</Button>,
                        ]}
                    >
                      <div>
                          <h6>Id:</h6>
                          <p>&nbsp;  {modalText.id} </p>
                      </div>
                      <hr/>

                      <div>
                        <h6>Name:</h6>
                        <p>&nbsp;  {modalText.name} </p>
                      </div>
                      <hr/>

                      <div>
                        <h6>Requested Date:</h6>
                        <p>&nbsp;  {modalText.scheduleDate} </p>
                      </div>
                      <hr/>

                      <div>
                        <h6>Preferred Time Slot:</h6>
                        <p>&nbsp;{modalText.scheduleTime} </p>
                      </div>
                      <hr/>

                      <div>
                        <h6>Phone:</h6>
                        <p>&nbsp;{modalText.phoneNumber} </p>
                      </div>
                      <hr/>

                      <div>
                        <h6>Email:</h6>
                        <p>&nbsp;{modalText.email} </p>
                      </div>
                      <hr/>

                      <div>
                        <h6>Message:</h6>
                        <p>&nbsp;{modalText.message} </p>
                      </div>
                      <hr/>
                     
                      <div>
                        <h6>Appointment Created at:</h6>
                        <p>{DateParseWithTime(modalText.createdAt)}</p>
                      </div>
                      <div>
                        
                        
                      </div>
                     
                    </Modal>
                  </TableCell>
                  <TableCell align="center"> <Button title="Delete" type="primary" onClick={()=>deleteItem(row._id)} className="buttonText"><MdDeleteForever style={{fontSize:"20px"}} /></Button></TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow >
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter >
              <TableRow style={{width:180}} >
                
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
       {
         isEmpty ? 
         
          <p style={{fontSize:"16px", textAlign:"center", marginTop:"20px"}}>No data found. Back to <Link to="/searchAppointment" > Search Appointments</Link></p>
         :
         ""
       }
        {/* <p style={{fontSize:"16px", textAlign:"center", marginTop:"20px"}}>{isEmpty ? `<Link to="/searchAppointment" >No data found</Link>`:""}</p> */}
      </div>
      
    </div>
  );
}

export default SearchApptTable;


