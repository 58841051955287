import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
const { WebLink } = require('../../../Export/ExportLink');

const axios = require('axios');
// require('dotenv').config()

const SearchAppt = () => {

  const [inputs,setInputs] = useState({
    name:"",
    email:"",
    dob:"",
    phoneNumber:"",
    scheduleDate:"",
    scheduleTime:""
  });
  const history = useHistory();

  const handleSubmit = (event) => {
    if(event){
      event.preventDefault();
    }
    
    history.push({
      pathname: '/searchAppointmentTable',
      state: inputs
    });

    return ;
    console.log("Input",inputs);

      //axios call start
      axios.post(WebLink+"/appt/search",inputs,{headers:{token:sessionStorage.getItem("token")} })
      .then(function (response) {
        // console.log(response.data)
        if(response.data.length != 0 ){
          console.log("okey",response.data)
        }else if(response.data.length == 0 ){
          return alert("No Data found");
        }else{
          console.log(response)
          return alert("Error found");
        }
       
      })
      .catch(function (error) {
        console.log(error);
      });
      // axios call end
      // console.log(inputs);
    
  }

  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }


  return (
    <div className="home">

       <div className="container">
        <h2 className="heading">Search Appointments </h2>
        <hr/>
        <form  className="form" onSubmit={handleSubmit}  autoComplete="off" >
          <div>
            <label > Name </label>
            <input  name="name" placeholder='Any name' onChange={handleInputChange} type="text" value={inputs.name} />
          </div>

          <div>
            <label > Email</label>
            <input  name="email" placeholder='email@email.com' onChange={handleInputChange} type="text" value={inputs.email} />
          </div>

          <div>
            <label > DOB</label>
            <input  name="dob" placeholder='MM/DD/YYYY' onChange={handleInputChange} type="text" value={inputs.dob} />
          </div>

          <div>
            <label> Schedule Date</label>
            <input  name="scheduleDate" placeholder='MM/DD/YYYY'  onChange={handleInputChange} type="text" value={inputs.scheduleDate} />
          </div>

          <div>
            <label> Schedule Time</label>
            <input  name="scheduleTime" placeholder="HH:MM" onChange={handleInputChange} type="text" value={inputs.scheduleTime} />
          </div>

          <div>
            <label> Phone Number</label>
            <input  name="phoneNumber" placeholder="HH:MM" onChange={handleInputChange} type="text" value={inputs.phoneNumber} />
          </div>
          
          <div className="submitDiv" >
              <input type="submit" value="Submit" className="submitbtn" />
          </div>
          
        </form>
      </div>

    </div>
  )
}

export default SearchAppt
