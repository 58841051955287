import React,{useState,useEffect} from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MdDeleteForever,MdUpdate } from 'react-icons/md';
import { FcViewDetails } from 'react-icons/fc';
const axios = require('axios');
const { WebLink } = require('../../../Export/ExportLink');






const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
}));


const AllTeams= ()=> {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');

  const [TableInfo, setTableInfo] = useState([]);

  const showModal = (body) => {
    setModalText(body);
    setVisible(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after one seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    //console.log('Clicked cancel button');
    setVisible(false);
  };

  const deleteItem = (_id) =>{
      // console.log(_id);
    let c = window.confirm("Want to delete ? ")
    if (c){
      axios.delete(WebLink+'/Doctor/'+_id,{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
        if(response.data.status == 403) window.location = "/";
        alert(response.data.text);
        window.location.reload(false);
        return true
      })
      .catch(function (error) {
        console.log(error)
      })
    }
    
    return true
  }

  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmZTJlMzhiNWVlZTMxNWU4Yzc3NmQ2MyIsImlhdCI6MTYwODc0NDUzMX0.T8Ck96I4qaFnwwwDm_OVJ6Vv2i29ozG5PkssfrWH1t0";
  useEffect( ()=> {
    axios.get(WebLink+"/Doctor",{headers:{token:sessionStorage.getItem("token")}}).then(function (response) {
         if(response.data.status == 403) window.location = "/";
        setTableInfo(response.data)
        
        return response.data
      })
      .catch(function (error) {
        
        console.log(error)
      })
    },[]);

    const DateParse=(date)=>{
      let d = new Date(date);
      return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()
    }
    

  return (
    <div className="home">
    
      <div className="tableContainer">
        <div className="tableHeading">
          <h3>All Team Members</h3>
        </div>
        <TableContainer component={Paper} className="TableView">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell align="center">Date&nbsp;</TableCell>
                <TableCell align="center">Show Info</TableCell>
                <TableCell align="center">Update</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (typeof(TableInfo)  == "object" ? TableInfo.map((item,index)=> 
                  <TableRow key={index}>
                  <TableCell component="th" scope="row"> {item.DoctorName}</TableCell>
                  <TableCell component="th" scope="row"> {item.Designation}</TableCell>
                  <TableCell align="center">{ DateParse(item.dateAdded) }</TableCell>
                  <TableCell align="center">
                  <Button type="primary" title="Details" onClick={()=>showModal(item)} className="buttonText">< FcViewDetails style={{fontSize:"20px",color:"white"}} /></Button>
                    <Modal
                        title={ (<h2>{ modalText.DoctorName}<span style={{fontSize:'18px'}}>,{modalText.Qualification}</span></h2>)}
                        visible={visible}
                        confirmLoading={confirmLoading}
                        keyboard ={true}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleCancel}>Close</Button>,
                        ]}
                    >
                        <div>
                        
                       <img src={WebLink+"/Image/"+modalText.ImageFileName}  alt="TeamImage" className="responsive_image" />
                      </div>
                      <hr/>
                      <div>
                        <h6>Name:</h6>
                        <p>&nbsp;  {modalText.DoctorName}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6>Designation:</h6>
                        <p>&nbsp;{modalText.Designation}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6>Qualification:</h6>
                        <p>&nbsp;{modalText.Qualification}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6> Info Part One:</h6>
                        <p>&nbsp;{modalText.ParaOne}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6> Info Part Two:</h6>
                        <p>&nbsp;{modalText.ParaTwo}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6> Info Part Three:</h6>
                        <p>&nbsp;{modalText.ParaThree}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6> Info Part Four:</h6>
                        <p>&nbsp;{modalText.ParaFour}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6>Description:</h6>
                        <p>&nbsp;{modalText.Description}</p>
                      </div>
                      <hr/>
                      <div>
                        <h6>Image Link:</h6>
                        <p> &nbsp; <a href={WebLink+"/Image/"+modalText.ImageFileName} target="_blank">{item.ImageOriginalName}</a></p>
                      </div>
                     
                    </Modal>
                </TableCell>
                <TableCell align="center"> <Button title="Update" type="primary"  className="buttonText"><Link to={"/TeamMember/"+item._id} className="UpdateLink"><MdUpdate style={{fontSize:"20px"}} className="UpdateIcon" /></Link> </Button></TableCell>
                <TableCell align="center"> <Button title="Delete" type="primary" onClick={()=>deleteItem(item._id)} className="buttonText"><MdDeleteForever style={{fontSize:"20px"}} /></Button></TableCell>
                </TableRow>
                ): ( <div>No one Added</div>))
               
              }
            </TableBody>
          </Table>
        </TableContainer>
        {TableInfo == "" ? (<div> <h4 style={{textAlign:'center'}}>No Members Added</h4> </div>):null}
      </div>
    </div>
  );
}

export default AllTeams;

